.cards{
    background-color: #F5F5F5;
    margin-top: 5px;
    height:auto;
}
.css-1auuqwc{
    border-radius: 10px;
}
.css-wru3b5{
    border-radius: 10px;
}
.css-wru3b5 .h2{
    font-size:medium;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}   
.css-kyxmw6{
    border-radius: 10px;
}
.css-kyxmw6 .h2{
    font-size:medium;
    font-family: Arial, Helvetica, sans-serif;
    color: #E7E7F8;
}
.itemContainer{
    display: flex;
    justify-content: space-around;

}
.itemContainer1{
    background-color: aquamarine;
    width:100px;
    height: 20vh;
}
/* styles.css */
.container {
    width: 40%;
    margin: 80px auto;
    /* Add more styles as needed */
}

/* Additional styles for child elements if necessary */

