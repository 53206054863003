.react-draggable{
    position: absolute;
    user-select: auto;
    width: 323px;
    height: min-content;
    display: inline-block;
    top: 0px;
    left: 0px;
    cursor: move;
    justify-content: center;
    border: 1px solid rgb(221, 221, 221);
    background: rgb(255, 255, 255);
    color: black;
    border-radius: 20px;
    padding: 20px;
    box-shadow: grey 0px 0px 5px;
    backdrop-filter: blur(10px);
    overflow: hidden;
    transform: translate(0px, 0px);
    max-width: 9.0072e15px;
    max-height: 9.0072e15px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .FilterModal {
    position: fixed !important;
    width: 50vw !important;
    top: 20%;
    z-index: 9999 !important;
  }
  .closemodule {
    margin-top: 10px !important;
  }
  .MassUploadModal {
    position: fixed !important;
    width: 50vw !important;
    top: 20%;
    left: 43.5%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .MassUpdateModal {
    position: fixed !important;
    width: 50vw !important;
    top: 15%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .EditMailModule {
    position: fixed !important;
    width: 50vw !important;
    top: 7%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .MassDeleteModule {
    position: fixed !important;
    width: 50vw !important;
    top: 7%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .MassUpdateModalConfirm {
    position: fixed !important;
    width: 50vw !important;
    top: 15%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .MassUploadModalConfirm {
    position: fixed !important;
    width: 50vw !important;
    top: 15%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
   .MassMailConfirmModule {
    position: fixed !important;
    width: 50vw !important;
    top: 15%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .OultlookMailModule {
    position: fixed !important;
    width: 50vw !important;
    top: 15%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }
  .DownloadExcelModule {
    position: fixed !important;
    width: 50vw !important;
    top: 15%;
    left: 55%; /* Center align horizontally */
    transform: translateX(-50%); /* Center the modal */
    z-index: 9999 !important;
  }

  