/* .duplicated {
  margin-left: 65%;
  margin-top: 5px;
} */

.react-draggable{
  position: absolute;
  user-select: auto;
  width: 323px;
  height: min-content;
  display: inline-block;
  top: 0px;
  left: -200px;
  cursor: move;
  justify-content: center;
  border: 1px solid rgb(221, 221, 221);
  background: rgb(255, 255, 255);
  color: black;
  border-radius: 20px;
  padding: 20px;
  box-shadow: grey 0px 0px 5px;
  backdrop-filter: blur(10px);
  overflow: hidden;
  transform: translate(0px, 0px);
  max-width: 9.0072e15px;
  max-height: 9.0072e15px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.UnallocateModal {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 45% !important; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  .duplicated {
    margin-left: -10%;
  }
}
@media (max-width: 1440px) {
  .duplicated {
    margin-left: 60%;
  }
}
@media (max-width: 600px) {
  .duplicated {
    margin-left: 5%;
  }
}
@media (max-width: 459px) {
}
