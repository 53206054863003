@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.top-bar {
  width: 100%;
  height: 50px;
}
.flexbox-container {
  display: flex;
  float: right;
  align-items: center;
}
.flexbox-item {
  margin: 10px;
  font-size: 12pt;
  font-family: "Times New Roman", Times, serif;
}
.flexbox-item-2 {
  padding: 5px;
  border-radius: 5px;
}
.center-text {
    text-align: center;
  }
  
.flexbox-item-3 {
  padding: 5px;
  border-radius: 5px;
}
.flexbox-item-4 {
  padding: 5px;
  border-radius: 5px;
}
.container-from-data {
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 15px;
}
.container-from-data .title {
  font-size: 25px;
  font-weight: 500;
  position: absolute;
}
.container-from-data .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
/* .content {
  padding-left: 220px;
  padding-right: 220px;
} */
.content .form-user .user-source{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
.form-user .user-source .input-box {
  margin-bottom: 15px;
  width: calc(60% / 1.5 - 40px);
  text-align: center;
  
  
}
.form-user .input-box span.user-source {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  .center-text {
    text-align: center;
  }
  
  }  
.user-source .input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  .center-text {
    text-align: center;
  }
  
}
.user-source .input-box select {
  height: 50px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px; 
  transition: all 0.3s ease;
  .center-text {
    text-align: center;
  }
  
}
.country {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.react-tel-input .form-control {
  position: relative !important;
  padding: 5px 5px;
  font-size: 14px !important;
  letter-spacing: 0.01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 30px !important;
  border: 1px solid #cacaca !important;
  border-radius: 5px !important;
  line-height: 25px !important;
  height: 6vh !important;
  max-width: 100% !important;
  outline: none !important;

}

.user-source {
  display: flex;
  /* padding-right: 300px; */
}

.input-box {
  display: block;
  text-align:Center;
}

.source {
  margin-right: 100px;
  float: left;
}
/* .user-source .input-box input:focus,
.user-source .input-box select,
.user-source .react-tel-input .form-control,
.user-source .input-box input:valid {
  border-color: #9b59b6;
} */
.react-tel-input .form-control form .button {
  height: 45px;
  margin: 35px 0;
  
}
form .button input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
form .button input:hover {
  /* transform: scale(0.99); */
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
}
@media (max-width: 768px) {
  .container-from-data {
    max-width: 100%;
  }
  .form-user .user-source {
    width: 100%;
  }
  .form-user .user-source .input-box {
    margin-bottom: 15px;
    width: 100%;

  }
  .centered-input {
    width: 100%; /* Ensures the input takes full width */
    text-align: center;/* Centers the text within the input field */
  }
  
  .content .form-user .user-source {
    max-height: 500px;
    overflow-y: scroll;
  }
  .user-source::-webkit-scrollbar {
    width: 5px;
  }
}
@media (max-width: 1440px) {
  .container-from-data {
    max-width: 100%;
  }
  .form-user .user-source .input-box {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
      
      
  }
  .content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .content .form-user .user-source {
    max-height: 500px;
    overflow-y: scroll;
  }
  .user-source::-webkit-scrollbar {
    width: 5px;
  }
}
@media (max-width: 600px) {
  .container-from-data {
    width: 100%;
  }
  .content {
    padding-left: 50px;
    padding-right: 0px;
  }
  .form-user .user-source .input-box {
    margin-bottom: 15px;
    max-width: 100%;
    
  }
  .content .form-user .user-source {
    max-height: 500px;
    overflow-y: scroll;
  }
  .user-source::-webkit-scrollbar {
    width: 5px;
  }
}
@media (max-width: 459px) {
  .container-from-data .content {
    flex-direction: column;
  }
  .content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .flexbox-container {
    display: flex;
  }
}
