/* Global container styling */
.container {
    display: flex;
    flex-direction: row;
    padding: 16px;
}

/* Sidebar */
.sidebar {
    flex: 0 0 250px; /* Fixed width for sidebar */
    background-color: #f7f7f7;
}

/* Main content */
.main-content {
    flex: 1; /* Takes the remaining space */
    padding: 16px;
    background-color: #f0f4f8;
}

/* Box shadows and border radius for general boxes */
.box {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 16px;
}

/* StatBox styling */
.stat-box {
    padding: 16px;
    background-color: #f9fafb;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

/* Icon button */
.stat-box .icon-button {
    background-color: #3182ce;
    color: white;
    border-radius: 50%;
    padding: 8px;
}

/* ChartBox styling */
.chart-box {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 16px;
}

/* Latest Announcement box styling */
.latest-announcement {
    background-color: #ffe3e6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-height: 400px;
    overflow-y: auto;
}

/* Calendar styling */
.react-calendar {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e2e8f0;
    background-color: white;
    font-family: Arial, sans-serif;
    width: 100%;
}

.react-calendar__tile {
    height: 50px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    text-align: center;
}

.react-calendar__tile:hover {
    background-color: #e2e8f0;
}

.react-calendar__tile--now {
    background-color: #f0e68c;
}

.react-calendar__tile--active {
    background-color: #36A2EB;
    color: white;
}

.react-calendar__tile--weekend {
    color: #d9534f;
}

/* Highlight today */
.highlight-today {
    background-color: #FFCE56 !important;
    color: white;
}
