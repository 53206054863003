.unallocated{
    margin-left: 65%;
    margin-top: 5px;
  }
  
  
  @media (max-width: 768px) {
     .unallocated{
        margin-left: -10%;
    }
   
  }
  @media (max-width: 1440px) {
    .unallocated{
        margin-left: 60%;
    }
   
  }
  @media (max-width: 600px) {
    .unallocated{
        margin-left: 5%;
    }
  }
  @media (max-width: 459px) {
   
  }