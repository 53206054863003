

/* Bruhat Logo */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }
  
  .logo-container img {
    margin-right: 5rem;
    height: 1.5rem;
  }
  
  .card-container {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .card {
    width: 100%;
    max-width: 1200px;
    border-radius: 0.375rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-heading {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1rem;
  }
  
  .card-description-list {
    padding-left: 1.5rem;
    list-style-type: decimal;
    list-style-position: inside;
  }
  
  .card-description-list li {
    margin-bottom: 0.5rem;
  }
  
  .toggle-switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toggle-switch-container .heading {
    font-size: 1.25rem;
    color: #2b6cb0;
  }
  
  .toggle-switch-container .switch-container {
    display: flex;
    align-items: center;
  }
  
  .upload-description {
    margin-top: 1rem;
    font-weight: 500;
    color: #2b6cb0;
  }
  
  .upload-input-container {
    margin-top: 1rem;
  }
  
  .upload-input-container .file-input {
    margin-bottom: 1rem;
  }
  
  .save-button-container {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  
  
  .bottom-buttons-container {
    position: relative;
    bottom: 4px;
    text-align: center;
    margin-top: 1rem;
  }
  
  .bottom-buttons-container .button {
    margin-right: 1rem;
  }
  