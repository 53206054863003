@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.top-bar {
  width: 100%;
  height: 50px;
}
.flexbox-container {
  display: flex;
  float: right;
  align-items: center;
}
.flexbox-item {
  margin: 10px;
  font-size: 12pt;
  font-family: "Times New Roman", Times, serif;
}
.flexbox-item-1 {
}
.flexbox-item-2 {
  padding: 5px;
  border-radius: 5px;
}
.flexbox-item-3 {
  padding: 5px;
  border-radius: 5px;
}
.flexbox-item-4 {
  padding: 5px;
  border-radius: 5px;
}
.error-tab {
  border: 1px solid red;
  background-color: #ffcccc;
}

.error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.container-from-data {
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
}
.container-from-data .title {
  font-size: 25px;
  font-weight: 500;
  position: absolute;
}
.container-from-data .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
/* .content {
  padding-left: 220px;
  padding-right: 220px;
} */
.content .form-user .user-booking {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
.form-user .user-booking .input-box {
  margin-bottom: 15px;
  width: calc(60% / 2 - 40px);
}
.form-user .input-box span.booking {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-booking .input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-booking .input-box select {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.user-booking .input-box Select {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.user-booking .input-box textarea {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.country {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
/* .PhoneInput {
  margin-top: 30px;
  width: 100%;
  height: 45px;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
} */

/* Select-react Component */

.user-booking .input-box .css-qbdosj-Input .css-1fdsijx-ValueContainer {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-booking .input-box .css-13cymwt-control {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-booking .input-box .SearchSelect{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
/* .css-13cymwt-control {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.css-13cymwt-control{
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
} */

/* .user-booking .input-box .css-fyq6mk-container {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
} */

.user-booking {
  display: flex;
  /* padding-right: 300px; */
}

.input-box {
  display: block;
  align-items: center;
}

.booking {
  margin-right: 100px;
  float: left;
}
/* .user-booking .input-box input:focus,
.user-booking .input-box select,
.user-booking .react-tel-input .form-control,
.user-booking .input-box input:valid {
  border-color: #9b59b6;
} */
.react-tel-input .form-control form .button {
  height: 45px;
  margin: 35px 0;
}
form .button input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}
form .button input:hover {
  /* transform: scale(0.99); */
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
}

.ConfirmCancelBooking {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  .container-from-data {
    max-width: 100%;
  }
  .form-user .user-booking {
    width: 100%;
  }
  .form-user .user-booking .input-box {
    margin-bottom: 15px;
    width: 100%;
  }
  .content .form-user .user-booking {
    max-height: 500px;
    overflow-y: scroll;
  }
  .user-booking::-webkit-scrollbar {
    width: 5px;
  }
}
@media (max-width: 1440px) {
  .container-from-data {
    max-width: 100%;
  }
  .form-user .user-booking .input-box {
    margin-bottom: 15px;
    width: 100%;
  }
  .content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .content .form-user .user-booking {
    max-height: 500px;
    overflow-y: scroll;
  }
  .user-booking::-webkit-scrollbar {
    width: 5px;
  }
}
@media (max-width: 600px) {
  .container-from-data {
    width: 100%;
  }
  .content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .form-user .user-booking .input-box {
    margin-bottom: 15px;
    max-width: 100%;
  }
  .content .form-user .user-booking {
    max-height: 500px;
    overflow-y: scroll;
  }
  .user-booking::-webkit-scrollbar {
    width: 5px;
  }
}
@media (max-width: 459px) {
  .container-from-data .content {
    flex-direction: column;
  }
  .content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .flexbox-container {
    display: flex;
  }
}

/* input icon */
.custom-input-container {
  position: relative;
}

.custom-input {
  padding-right: 30px; /* Adjust this value based on the icon size */
}

.cancel-icon {
  position: absolute;
  top: 70%;
  right: 10px; /* Adjust the distance from the right */
  transform: translateY(-50%);
}
/* input icon end */
