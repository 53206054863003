.update {
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
/* .update:hover::after {
  content: "Update";
  position: absolute;
  float: right;
  justify-content: space-between;
  white-space: nowrap;
} */
.mail {
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
/* .mail:hover::after {
  content: "Mail";
  position: absolute;
  float: right;
  justify-content: space-between;
  white-space: nowrap;
} */
.delete {
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
/* .delete:hover::after {
  content: "Delete";
  position: absolute;
  float: right;
  justify-content: space-between;
  white-space: nowrap;
} */
.table-container {
  width: 100%;
  position: relative;
  top: 0px;
  overflow-y: scroll;
  overflow-x: scroll;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
}
.table-container thead th {
  padding: 10px 10px;
}
.table-container tbody td {
  padding: 10px 10px;
  text-align: left;
}
