@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
/* AddEmployee.css */

.top-bar {
  width: 100%;
  height: 50px;
}

.flexbox-container {
  display: flex;
  float: right;
  align-items: center;
}

.flexbox-item {
  margin: 10px;
  font-size: 12pt;
  font-family: "Times New Roman", Times, serif;
}

.flexbox-item-2 {
  padding: 5px;
  border-radius: 5px;
}

.flexbox-item-3 {
  padding: 5px;
  border-radius: 5px;
}

.flexbox-item-4 {
  padding: 5px;
  border-radius: 5px;
}

.container-from-data {
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 5px;
}

.container-from-data .title {
  font-size: 25px;
  font-weight: 500;
  position: absolute;
}

.container-from-data .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}

/* .content {
  padding-left: 220px;
  padding-right: 220px;
} */

.form-user-emp {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
}

.user-employee {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.input-box {
  width: calc(100% / 1.5 - 40px);/* Adjust width for two columns with gap */
}

.form-user-emp .input-box {
  margin-bottom: 15px;
  width: 50%;
}

.form-user-emp .input-box FormLabel.employee {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-employee .input-box Input,
.user-employee .input-box Select {
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.user-employee .input-box Input:focus,
.user-employee .input-box Input:valid {
  border-color: #9b59b6;
}

form .button {
  height: 45px;
  margin: 35px 0;
}

form .button Input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
}

form .button Input:hover {
  background: linear-gradient(-135deg, #71b7e6, #9b59b6);
}

@media (max-width: 768px) {
  .container-from-data {
    max-width: 100%;
  }

  .form-user-emp {
    flex-direction: column;
  }

  .form-user-emp .input-box {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .container-from-data {
    max-width: 100%;
  }

  .form-user-emp {
    flex-direction: column;
  }

  .form-user-emp .input-box {
    width: 100%;
  }

  .content {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 600px) {
  .container-from-data {
    width: 100%;
  }

  .content {
    padding-left: 0;
    padding-right: 0;
  }

  .form-user-emp {
    flex-direction: column;
  }

  .form-user-emp .input-box {
    width: 100%;
  }
}

@media (max-width: 459px) {
  .container-from-data .content {
    flex-direction: column;
  }

  .content {
    padding-left: 0;
    padding-right: 0;
  }

  .flexbox-container {
    display: flex;
  }
  .checkbox-group {
    display: flex;
    flex-direction:column-reverse; /* Align items vertically */
  }
  
  .checkbox-group .chakra-checkbox {
    margin-bottom: 8px; /* Add space between checkboxes */
  }
  
  
  
  
}
